.language {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  user-select: none;
}

.language:hover {
  color: var(--color-primary);
}
