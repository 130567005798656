.footer {
  position: relative;
  display: flex;
  height: var(--footer-size);
  background: var(--color-dark);
  color: white;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  font-size: 14px;
}

.footer_section {
  display: flex;
  align-items: center;
  height: 100%;
}

.footer_section:nth-child(1) {
  width: calc(400px - 30px);
  justify-content: space-between;
}

.footer_divider {
  height: 100%;
  width: 1px;
  background: #2d3958;
  border-radius: 4px;
  margin-left: 30px;
}

.footer div {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.footer_button {
  height: 40px;
  padding: 0 20px;
  border-radius: 25px;
  background-color: var(--color-red);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.6s;
  cursor: pointer;
  user-select: none;
}
