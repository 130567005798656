.button {
  height: 35px;
  border-radius: 40px;
  border: 1px solid var(--color-dark-4);
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  user-select: none;
  margin: 10px 0;
}

.button--active {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 1px var(--color-primary);
  font-weight: 700;
}

.button--can-click {
  cursor: pointer;
}

.button--disabled {
  opacity: 0.3;
  pointer-events: none;
  background: var(--color-grey);
}
