.main {
  width: 640px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: var(--discount-banner-height);
}

.discount-banner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  background: var(--color-primary);
  text-align: center;
  color: white;
  font-size: 14px;
  height: var(--discount-banner-height);
  padding: 0 30px;
}

.discount-banner span {
  font-size: 16px;
  margin-left: 5px;
}

@media screen and (max-width: 1080px) {
  .main {
    width: 100%;
    padding: 30px 30px calc(30px + var(--discount-banner-height));
    max-width: auto;
  }

  .header_step {
    width: auto;
    min-width: 50px;
  }

  .header_step span {
    display: none;
  }

  .header_step--active {
    width: 160px;
  }

  .header_step--active span {
    display: block;
  }

  .main iframe {
    width: 352px;
    height: 198px;
  }
}

@media screen and (max-width: 780px) {
  :root {
    --footer-size: 140px;
  }

  .discount-banner {
    font-size: 12px;
  }

  .drawer {
    width: 100%;
    height: auto;
    /*border-bottom: 1px solid var(--color-dark-4);*/
  }

  .header {
    padding: 0 30px;
  }

  .header_logo {
    width: 36px;
    height: 35px;
  }

  .footer-button {
    width: 190px;
    box-sizing: border-box;
  }

  #ADME_BRAND_LOGO_final {
    display: none;
  }

  .header_step {
    width: 50px;
  }

  .header_step span {
    display: none;
  }

  .header_step-light {
    font-size: 12px;
    width: 50px;
  }

  .app_body--summary {
    flex-direction: column;
  }

  .app_main {
    display: block !important;
  }

  .footer_divider {
    display: none;
  }

  .footer_section {
    height: auto;
  }

  .footer_section:nth-child(1) {
    width: 100%;
    height: 82%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer {
    padding-right: 100px;
  }
}

.main .player {
  position: relative;
  width: 100%;
}

.main .player_sizer {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.main iframe {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 400px) {
  .header_step {
    width: 30px;
    min-width: 30px;
  }
}

.price {
  -webkit-tap-highlight-color: transparent;
}

.header_step {
  -webkit-tap-highlight-color: transparent;
}

.language {
  -webkit-tap-highlight-color: transparent;
  font-weight: bold;
}

.main p {
  margin-bottom: 30px;
}

.main h2 {
  margin-bottom: 20px;
}

.main form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main_input-controller {
  width: 48%;
  margin-bottom: 30px;
}

.main_input-controller-area {
  width: 100%;
}

.main_input-label {
  font-family: 'Montserrat', sans-serif;
  display: block;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 14px;
}

.main_input-label-accent {
  color: var(--color-primary);
}

.main_input {
  border-radius: 25px;
  border: 1px solid var(--color-grey);
  padding: 10px 15px;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.main_input-area {
  padding: 15px;
  height: 120px;
  border-radius: 4px;
}

.vimeo-frame {
  border-radius: 8px;
}

.submit-form {
  width: 100%;
}
