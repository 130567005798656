.drawer {
  width: 400px;
  flex-shrink: 0;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  font-size: 12px;
  position: relative;
}

.drawer .drawer-step {
  border-right: 1px solid var(--color-grey);
  padding: 30px;
  height: calc(100% - (var(--discount-banner-height) + 60px));
  overflow: auto;
}

.drawer_button {
  height: 40px;
  border-radius: 40px;
  border: 2px solid var(--color-grey);
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
}

.drawer_button_inc,
.drawer_button_dec {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--color-grey);
  cursor: pointer;
  user-select: none;
}

.drawer_button_inc {
  margin-left: 5px;
}

h2 {
  font-size: 18px;
  margin: 30px 0 10px;
}

h2:first-of-type {
  margin-top: 0;
}

h2,
h3,
p {
  margin-top: 0;
}

h3 {
  font-size: 14px;
  margin: 0 0 10px;
}

.button + h2,
.button + h3 {
  margin-top: 25px;
}

p {
  margin: 0 0 20px;
}

.summary_item {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  border-top: 1px solid var(--color-grey);
}

.summary_item:last-child {
  border-bottom: 1px solid var(--color-grey);
}

.summary h3 {
  margin: 25px 0 20px 0;
}

@media screen and (max-width: 1080px) {

}