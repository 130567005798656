:root {
  --primary-color: #282942;
  --primary-color-light: #484969;
  --primary-color-lighter: #70718f;
  --primary-color-text: white;
  --color-dark: #20283E;
  --color-grey: #dadce7;
  --color-red: #ff237b;

  --color-primary: #573AEA;
  --color-secondary: #5c45ff;
  --color-dark-1: #000000;
  --color-dark-2: #2d3958;
  --color-dark-3: #7a88a8;
  --color-dark-4: #dadce7;
  --color-dark-5: #f3f4f7;
  --color-dark-6: #fbfbfc;
  --color-dark-7: #fff;

  --header-size: 60px;
  --footer-size: 100px;
  --discount-banner-height: 70px;
}

body,
html,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: 'Montserrat', sans-serif;
}

p,
div {
  line-height: 16px;
}

.svg-primary-color {
  fill: var(--color-red);
}

#root-2 {
  position: absolute;
  top: 0;
  left: 0;
}
