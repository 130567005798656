.header {
  display: flex;
  width: 100%;
  height: var(--header-size);
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-grey);
  padding: 0 30px;
  font-size: 14px;
}

.header_section {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
}

.header_logo svg {
  height: 30px;
  width: 100px;
  transform: translateX(-8px);
}

.header_step {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  position: relative;
  width: 160px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-dark-1);
  text-decoration: none;
}

.header_step--active,
.header_step:hover {
  color: var(--color-primary);
}

.header_step-light {
  position: absolute;
  height: 3px;
  width: 160px;
  background: var(--color-primary);
  bottom: -1px;
  left: 0;
  transition: 0.3s;
  border-radius: 4px;
}

.lang-switcher {
  font-family: 'Montserrat', sans-serif;
}
