.price_total {
  font-size: 18px;
  margin-bottom: 5px;
}

.price {
  position: relative;
  padding: 12px 20px;
  padding-right: 50px;
  border: 1px solid var(--color-dark-2);
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.price_dropdown {
  display: none;
  position: absolute;
  background: var(--color-dark-1);
  width: 100%;
  padding: 5px 0;
  bottom: -1px;
  left: -1px;
  border-radius: 20px;
  border: 1px solid var(--color-dark-2);
  overflow: hidden;
  z-index: 2;
}

.price_dropdown--show {
  display: block;
}

.price_dropdown-item {
  padding: 7px 20px;
}

.price_dropdown-item:hover {
  background: var(--color-dark-2);
}

.price_icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
