.number-button-module {
  display: flex;
}

.number-button-module div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.2s;
}

.number-button-module div:nth-child(2) {
  margin-left: 5px;
}

.number-button-module--min div:nth-child(1) {
  opacity: 0.3;
}

.number-button-module--max div:nth-child(2) {
  opacity: 0.3;
}

.svg-icon_primary {
  fill: var(--color-dark-1);
  fill-opacity: 1;
}

.svg-icon_secondary {
  fill: var(--color-dark-5);
  fill-opacity: 1;
}
