.app {
  height: 100%;
  color: var(--color-dark);
}

.app_body-wrapper {
  height: calc(100% - var(--header-size) - var(--footer-size));
  overflow: hidden;
}

.app_body {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.app_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: #fbfbfc;
  position: relative;
}

@media screen and (max-width: 780px) {
  .app_body-wrapper {
    flex-direction: column-reverse;
    overflow-y: auto !important;
  }

  .app_body {
    flex-direction: column-reverse;
    height: auto;
    min-height: 100%;
  }
}
