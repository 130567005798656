.message {
  position: fixed;
  padding: 9px 15px;
  bottom: 30px;
  left: 430px;
  border-radius: 25px;
  color: var(--color-dark-4);
  border: 1px solid var(--color-dark-2);
  display: flex;
  align-items: center;
  transition: 0.1s;
}

.message_icon {
  width: 20px;
  height: 20px;
  background: var(--color-dark-2);
  color: var(--color-dark-3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 3px;
}

.message_icon > span {
  transform: scale(0.6);
}

.message_text {
  margin: 0 15px;
}

.message--hidden {
  opacity: 0;
  transform: translateY(15px);
}
